<template>
  <div>
    <titulo-aulas />

    <filtro-aulas @filtrar="filtrar" @limparFiltro="limparTabela" />

    <b-row class="justify-content-end">
      <b-col cols="12" md="auto">
        <b-button
          @click="openModal('aula')"
          :disabled="btnCadastrar"
          variant="info"
          class="my-4 w-100"
        >
          {{ $t('ACADEMICO_DIARIO_CLASSE.CADASTRAR_AULA') }}
        </b-button>
      </b-col>
    </b-row>

    <tabela-aulas
      :aulas="resultadoBusca"
      @editar-aula="openModal"
      @excluir="confirmarExclusao"
    />

    <modal-aulas
      :form="modais.aulaDados"
      :exibir="modais.aula"
      @salvar="salvar"
      @fechar="closeModal('aula')"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import breadcrumb from '@/common/utils/breadcrumb';
import mensagem from '@/common/utils/mensagem';

// Service & Models:
import { Aula } from '@/common/models';
import AulasService from '@/common/services/academico/aulas.service.js';

// Components:
// import { Cabecalho } from '@/components/headers/index';
import TabelaAulas from './components/aulas/Tabela';
import ModalAulas from './components/aulas/Modal';
import FiltroAulas from '@/views/academico/diario-classe/components/Opcao01';
import TituloAulas from '@/views/academico/diario-classe/components/aulas/TituloAulas';

export default {
  components: {
    TituloAulas,
    TabelaAulas,
    ModalAulas,
    FiltroAulas,
  },
  mounted() {
    breadcrumb.definir(this.$store, [
      { titulo: this.$t('ACADEMICO_DIARIO_CLASSE.ACADEMICO') },
      { titulo: this.$t('ACADEMICO_DIARIO_CLASSE.DIARIO_CLASSE') },
      { titulo: this.$t('ACADEMICO_DIARIO_CLASSE.AULAS') },
    ]);
  },
  data() {
    return {
      filtro: {},
      resultadoBusca: [],
      modais: {
        aula: false,
        aulaDados: {},
      },
      btnCadastrar: true,
    };
  },
  watch: {
    filtro: 'verificarHabilitarCadastro',
  },
  methods: {
    // FUNÇÕES MODAL
    openModal(key, item) {
      this.modais[key] = true;
      item
        ? (this.modais[`${key}Dados`] = JSON.parse(JSON.stringify(item)))
        : (this.modais[`${key}Dados`] = new Aula({}));
    },
    closeModal(key) {
      return (this.modais[key] = false);
    },
    // FUNÇÕES DO FILTRO:
    filtrar(filtroUtilizado) {
      filtroUtilizado ? (this.filtro = filtroUtilizado) : {};
      let filtroBackEnd = {
        segmentacaoId: this.filtro.segmentacaoId,
        turmaId: this.filtro.turmaId,
        disciplinaId: this.filtro.disciplinaId,
        tamanhoDaPagina: 50,
        numeroDaPagina: 1,
      };
      this.$store.dispatch(START_LOADING);
      AulasService.obterPorFiltro(filtroBackEnd)
        .then(({ data }) => {
          this.resultadoBusca = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    limparTabela() {
      this.resultadoBusca = [];
    },
    // FUNÇÕES AUXILIARES FORM
    verificarHabilitarCadastro() {
      this.filtro.segmentacaoId.length &&
      this.filtro.turmaId.length &&
      this.filtro.disciplinaId.length
        ? (this.btnCadastrar = false)
        : (this.btnCadastrar = true);
    },
    // FUNÇÕES CRUD
    salvar() {
      // p/ criar aula:
      if (!this.modais.aulaDados.id) {
        this.modais.aulaDados.segmentacaoId = this.filtro.segmentacaoId;
        this.modais.aulaDados.turmaId = this.filtro.turmaId;
        this.modais.aulaDados.disciplinaId = this.filtro.disciplinaId;

        return this.criar();
      }
      return this.editar();
    },
    criar() {
      this.$store.dispatch(START_LOADING);
      AulasService.criar(this.modais.aulaDados)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            'Aula criada com sucesso.'
          );
          this.filtrar();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.closeModal('aula');
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editar() {
      this.$store.dispatch(START_LOADING);
      AulasService.editar(this.modais.aulaDados)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            'Aula editada com sucesso.'
          );
          this.filtrar();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.closeModal('aula');
          this.$store.dispatch(STOP_LOADING);
        });
    },
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        null,
        this.$t('ACADEMICO.CONFIRMAR_EXCLUIR_AULA')
      );
      if (respostaExclusao.isConfirmed) {
        this.excluir(item);
      }
    },
    excluir(aulaId) {
      this.$store.dispatch(START_LOADING);
      AulasService.excluir(aulaId)
        .then(() => {
          this.filtrar();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.closeModal('aula');
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
